import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import cashRegister from '@/repositories/api/cash-register';
import { CashRegisterData } from '@/types';

@Module
export default class Settings extends VuexModule {
  cashRegisterData: CashRegisterData|null = null;

  get cashRegister(): CashRegisterData|null {
    return this.cashRegisterData;
  }

  @Mutation
  SET_CASH_REGISTER(data: CashRegisterData): void {
    this.cashRegisterData = data;
  }

  @Action({ commit: 'SET_CASH_REGISTER' })
  async initCashRegister(): Promise<CashRegisterData|null> {
    try {
      const res = await cashRegister.get();
      const responseData = res.data.data;
      if (responseData) {
        return {
          balance: responseData.balance,
        };
      }
      this.context.dispatch('showAlertError', 'Произошла ошибка при загрузке приложения');
      return null;
    } catch (e) {
      this.context.dispatch('showAlertError', 'Произошла ошибка при загрузке приложения');
      return null;
    }
  }
}
