import { AxiosPromise } from 'axios';
import api from '@/repositories/api';
import { CleanerModel, DataResponse } from '@/types';
import { CleanerListItem } from '@/types/api/cleaners';

export default {
  getList(): AxiosPromise<DataResponse<CleanerListItem[]>> {
    return api.get('/cleaners');
  },
  get(id: number): AxiosPromise<DataResponse<CleanerModel>> {
    return api.get(`/cleaners/${id}`);
  },
  create(data: any): AxiosPromise<DataResponse<CleanerModel>> {
    return api.post('/cleaners', data);
  },
  update(data: Date, id: number): AxiosPromise<DataResponse<CleanerModel>> {
    return api.put(`/cleaners/${id}`, data);
  },
};
