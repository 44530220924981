<template>
  <CSidebar
      position="fixed"
      :unfoldable="sidebarUnfoldable"
      :visible="sidebarVisible"
      @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <div class="sidebar-logo">
      <img :src="logo">
    </div>
    <div class="sidebar-cash-register-balance">
      В кассе: {{ $helpers.formatCurrency(cashRegister?.balance || 0) }}
    </div>
    <div class="d-flex flex-column justify-content-between h-100">
      <AppSidebarNav/>
      <CNav class="sidebar-nav mb-5">
        <CNavItem>
          <CNavLink href="#" @click="onLogoutBtnClick">
            <CIcon icon="cil-account-logout" class="nav-icon icon-c-s"></CIcon>
            Выйти
          </CNavLink>
        </CNavItem>
      </CNav>
    </div>
  </CSidebar>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Cookie from 'js-cookie';
import { mapGetters } from 'vuex';
import auth from '@/repositories/api/auth';
import { tokenCookieKey } from '@/app.config';
import logo from '@/assets/images/logo.svg';
import AppSidebarNav from '@/components/AppSidebarNav.vue';

@Options({
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  computed: mapGetters({
    cashRegister: 'cashRegister',
  }),
})
export default class AppSidebar extends Vue {
  logo = logo;

  get sidebarUnfoldable() {
    return this.$store.state.sidebarUnfoldable;
  }
  get sidebarVisible() {
    return this.$store.state.sidebarVisible;
  }
  onLogoutBtnClick(event) {
    auth.logout()
      .then((res) => {
        if (res.status === 200) {
          window.location.href = '/auth/login';
          Cookie.remove(tokenCookieKey);
        } else {
          this.$store.dispatch('showAlertError', 'Произошла ошибка');
        }
      })
      .catch(() => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка');
      });
  }
}
</script>
