<template>
  <router-link v-if="navItem.to" custom :to="navItem.to"
               v-slot="{ navigate, isActive }">
    <component
        :is="navItem.component"
        href="javascript:void(0)"
        :active="isActive"
        @click="navigate"
    >
      <CIcon v-if="navItem.icon"
             :name="navItem.icon" custom-class-name="nav-icon"/>
      {{ navItem.name }}
      <CBadge v-if="navItem.badge"
              :color="navItem.badge.color"
              class="ms-auto">
        {{ navItem.badge.text }}
      </CBadge>
    </component>
  </router-link>
  <component v-else
             :is="navItem.component"
  >{{ navItem.name }}</component>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/vue';

@Options({
  name: 'AppSidebarNavItem',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  props: {
    navItem: {
      type: Object,
      required: true,
    },
  },
})
export default class AppSidebarNavItem extends Vue {
}
</script>
