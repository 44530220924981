import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CFormInput = _resolveComponent("CFormInput")!

  return (_openBlock(), _createBlock(_component_CFormInput, {
    ref: "input",
    type: "text",
    modelValue: _ctx.localValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event))
  }, null, 8, ["modelValue"]))
}