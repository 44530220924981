import {
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';

@Module
export default class Header extends VuexModule {
  title: string|null = null

  get headerTitle(): string|null {
    return this.title;
  }

  @Mutation
  SET_HEADER_TITLE(title: string): void {
    this.title = title;
  }
}
