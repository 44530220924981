export default [
  {
    component: 'CNavTitle',
    name: 'Меню',
  },
  {
    component: 'CNavItem',
    name: 'Отчеты',
    to: '/reports',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Клинера',
    to: '/cleaners',
    icon: 'cil-sign-language',
  },
  {
    component: 'CNavItem',
    name: 'Пользователи',
    to: '/users',
    icon: 'cil-group',
    userRoles: ['admin'],
  },
];
