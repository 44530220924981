import { createApp } from 'vue';
import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import VCalendar from 'v-calendar';
import { BIconEyeFill, BIconEyeSlashFill } from 'bootstrap-icons-vue';
import App from './App.vue';
import config from '@/plugins/config.plugin';
import helpers from '@/plugins/helpers.plugin';
import router from './router';
import store from './store';
import 'v-calendar/dist/style.css';

import icons from '@/assets/icons';

const app = createApp(App);
app.use(config);
app.use(helpers);
app.use(VCalendar);
app.component('BIconEyeFill', BIconEyeFill);
app.component('BIconEyeSlashFill', BIconEyeSlashFill);
app.use(store);
app.use(router);
app.provide('icons', icons);
app.use(CoreuiVue);
app.component('CIcon', CIcon);
app.mount('#app');
