
import { Options, Vue } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import reports from '@/repositories/api/reports';
import { DataResponse, ReportListItem, ReportManagerProps } from '@/types';

export interface ReportListItemProps {
  id: number,
  reportDate: string,
  manager: ReportManagerProps,
  cleaningCount: number,
  dryCleaningCount: number,
}

@Options({
  name: 'ReportsList',
  computed: mapGetters({
    isCurrentUserAdmin: 'isCurrentUserAdmin',
  }),
})
export default class ReportsList extends Vue {
  isLoading = true;
  reports: ReportListItemProps[] = [];
  successSave = false;
  deleteReport: ReportListItemProps|null = null;
  deleteReportLoading = false;
  successDelete = false;

  checkSuccessMessage(): void {
    if (this.$route.query['success-save'] === undefined) return;
    this.successSave = true;
    this.$router.replace(
      this.$route.fullPath.replace(/success-save=?[^&]*&?/, ''),
    );
  }

  created(): void {
    this.$store.commit('SET_HEADER_TITLE', 'Список отчетов');
    this.checkSuccessMessage();
    this.loadReports();
  }

  loadReports(): void {
    this.isLoading = true;
    reports
      .getList()
      .then((value: AxiosResponse<DataResponse<ReportListItem[]>>) => {
        this.reports = value.data.data.map((item) => ({
          id: item.id,
          reportDate: moment(item.report_date).format('DD.MM.YYYY'),
          cleaningCount: item.cleaning_count,
          dryCleaningCount: item.dry_cleaning_count,
          manager: {
            id: item.manager.id,
            name: item.manager.name,
          },
        }));
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке отчетов');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onReportDeleteModalClose(): void {
    this.deleteReport = null;
  }

  onDeleteReportButtonClick(): void {
    this.successDelete = false;
    if (this.deleteReport === null) return;
    this.deleteReportLoading = true;
    reports
      .delete(this.deleteReport.id)
      .then((response: AxiosResponse) => {
        if (response.status === 204) {
          this.deleteReport = null;
          this.successDelete = true;
          this.loadReports();
          this.$store.dispatch('initCashRegister');
        } else {
          this.$store.dispatch('showAlertError', 'Произошла ошибка при удалении отчета');
        }
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при удалении отчета');
      })
      .finally(() => {
        this.deleteReportLoading = false;
      });
  }
}
