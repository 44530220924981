import {
  CreateFieldValidator,
  CreateFormValidator,
  FieldValidator,
  FormValidator,
} from '@/types/index';

export const useField = (field: CreateFieldValidator): FieldValidator => {
  const validators = field.validators || [];
  return {
    value: field.value,
    errors: [],
    custom_errors: [],
    blur: false,
    validators,
    mask: field.mask || '',
  };
};

export const validateField = (field: FieldValidator, val: any = null): void => {
  field.errors = [];
  for (const validator of field.validators) {
    let valid: boolean | string = true;
    if (val) {
      valid = validator(val);
    } else {
      valid = validator(field.value);
    }
    if (typeof valid === 'string' || !valid) {
      if (typeof valid === 'string') {
        field.errors.push(valid);
      } else if (!valid) {
        field.errors.push('Untitled Error');
      }
    }
  }
};

export const useForm = <T>(fields: CreateFormValidator<T>): FormValidator<T> => {
  const form: FormValidator = {};
  for (const [fieldName, field] of Object.entries(fields)) {
    form[fieldName] = useField(field as CreateFieldValidator);
  }
  return form;
};

export function isFormValid(formName = 'form') {
  return function (this: any): boolean {
    const form: FormValidator = this[formName];
    for (const field of Object.values(form)) {
      if (field.errors.length > 0 || field.custom_errors.length > 0) {
        return false;
      }
    }
    return true;
  };
}

// // eslint-disable-next-line max-len
// export const parseErrorResponse = <T = any, E = T>
// (form: FormValidator<T>, data: ResponseErrorData<E>): void => {
//   const isValidationError = data.error_type === ResponseErrorTypes.VALIDATION;
//   if (isValidationError && data.validation_errors) {
//     for (const [fieldName, errors] of Object.entries(data.validation_errors)) {
//       if (fieldName in form) {
//         const field = (form as any)[fieldName];
//         field.errors = errors;
//         field.blur = true;
//       }
//     }
//   }
// };

export const clearForm = <T = any>(form: FormValidator<T>): void => {
  for (const fieldName of Object.keys(form)) {
    if (fieldName in form) {
      const field = (form as any)[fieldName];
      field.errors = [];
      field.custom_errors = [];
      field.blur = false;
      field.value = '';
    }
  }
};
