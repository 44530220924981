
import { Options, Vue } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { mapGetters } from 'vuex';
import AppInput from '@/components/AppInput.vue';
import {
  DataResponse,
  ReportAdditionalReport,
  ReportAdditionalReportOther,
  ReportAdditionalReportPurchase,
  ReportAdditionalReportPuttingMoney,
  ReportModel,
  ReportOrder,
  AdditionalReportType,
  ReportAlteration,
  OrderProps,
  AlterationProps,
  OrderCleanerProps,
  AlterationCleanerProps,
  AdditionalReportProps,
  ReportProps,
  AdditionalReportPuttingMoneyProps,
  AdditionalReportPurchaseProps,
  AdditionalReportOtherProps,
  CleanerListItem, CashRegisterData,
} from '@/types';
import reports from '@/repositories/api/reports';
import { reportApiCaster } from '@/utils/report/report-api-caster';
import { convertUnprocessableContentResponseToHtml } from '@/utils/api/unprocessable-content-handler';
import { CleanerListItemProps } from '@/views/cleaners/CleanersList.vue';
import cleaners from '@/repositories/api/cleaners';

@Options({
  name: 'ReportsForm',
  computed: mapGetters({
    cashRegister: 'cashRegister',
  }),
  components: {
    AppInput,
  },
  watch: {
    'report.reportDate': function () {
      if (this.report.id === null && this.report.reportDate !== null) {
        this.checkReportDateAvailable();
      }
    },
  },
})
export default class ReportsForm extends Vue {
  readonly cashRegister!: CashRegisterData;
  editOrder: OrderProps|null = null;
  editAlteration: AlterationProps|null = null;
  orderModalTabPanelActiveKey = 1;
  alterationModalTabPanelActiveKey = 1;
  editOrderCleaner: OrderCleanerProps|null = null;
  editAlterationCleaner: AlterationCleanerProps|null = null;
  editAdditionalReport: AdditionalReportProps|null = null;
  lastAddedOrderCleanerId = 0;
  lastAddedAlterationCleanerId = 0;
  lastAddedOrderId = 0;
  lastAddedAlterationId = 0;
  currentCashRegisterBalance = 0;
  lastAddedAdditionalReportId = 0;
  report: ReportProps = this.emptyReport();
  isLoading = true;
  savingLoading = false;
  reportDateIsAvailable = true;
  validationErrors: HTMLElement|null = null;
  cleaners: CleanerListItemProps[] = [];

  created(): void {
    const reportId = +this.$route.params.reportId || null;
    if (reportId !== null) {
      this.$store.commit('SET_HEADER_TITLE', 'Редактирование отчета');
      this.loadReport(reportId);
    } else {
      this.isLoading = false;
      this.$store.commit('SET_HEADER_TITLE', 'Создание отчета');
    }
    this.loadCleaners();
  }

  loadCleaners() {
    cleaners
      .getList()
      .then((value: AxiosResponse<DataResponse<CleanerListItem[]>>) => {
        this.cleaners = value.data.data.map((item) => ({
          id: item.id,
          firstName: item.first_name,
          lastName: item.last_name,
          isFired: item.is_fired,
        }));
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке клинеров. Перезагрузите страницу');
      });
  }

  get filteredCleaners(): CleanerListItemProps[] {
    return this.cleaners.filter((cleaner) => !cleaner.isFired
      || cleaner.id === this.editOrderCleaner?.cleaner?.id
      || cleaner.id === this.editAlterationCleaner?.cleaner?.id);
  }

  get cashRegisterBalance(): number {
    return this.report.id === null
      ? this.currentCashRegisterBalance
      : (this.report.cashRegisterBalance ?? 0);
  }

  async saveReport(isFinal: boolean) {
    const data = reportApiCaster(this.report);
    data.is_final = isFinal;

    this.validationErrors = null;
    this.savingLoading = true;
    try {
      const response = this.report.id === null
        ? await reports.create(data)
        : await reports.update(data, this.report.id);
      this.savingLoading = false;
      if (response.status !== 200 && response.status !== 201) {
        this.$store.dispatch('showAlertError', 'Произошла ошибка. Попробуйте снова');
      } else {
        this.$router.push('/reports?success-save');
      }
    } catch (e) {
      if (e.response !== undefined && e.response.status === 422 && e.response.data !== undefined) {
        this.validationErrors = convertUnprocessableContentResponseToHtml(e.response.data);
      } else {
        this.$store.dispatch('showAlertError', 'Произошла ошибка. Попробуйте снова');
      }
    } finally {
      this.savingLoading = false;
    }
  }

  async checkReportDateAvailable(): Promise<void> {
    if (this.report.reportDate === null) return;
    this.reportDateIsAvailable = true;
    this.isLoading = true;
    try {
      const checkingResult = await reports.checkDate(this.report.reportDate);
      if (!checkingResult.data) {
        this.report.reportDate = null;
        await this.$store
          .dispatch('showAlertError', 'Произошла ошибка, попробуйте еще раз');
        return;
      }
      this.reportDateIsAvailable = checkingResult.data.available;
      if (!this.reportDateIsAvailable) {
        this.report.reportDate = null;
      } else {
        this.currentCashRegisterBalance = checkingResult.data.cash_register_balance;
      }
    } catch (e) {
      console.error(e);
      await this.$store
        .dispatch('showAlertError', 'Произошла ошибка, попробуйте еще раз');
    } finally {
      this.isLoading = false;
    }
  }

  emptyReport(): ReportProps {
    return {
      id: null,
      orders: [],
      additionalReports: [],
      alterations: [],
      deletedOrders: [],
      deletedAdditionalReports: [],
      deletedAlterations: [],
      additionalInfo: null,
      encashment: 0,
      manager: null,
      reportDate: null,
      cashRegisterBalance: null,
    };
  }

  loadReport(reportId: number) {
    reports
      .get(reportId)
      .then((value: AxiosResponse<DataResponse<ReportModel>>) => {
        this.castReport(value.data.data);
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке отчета');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  castReport(report: ReportModel) {
    this.report = {
      id: report.id,
      orders: this.castReportOrders(report.orders),
      alterations: this.castReportAlterations(report.alterations),
      additionalReports: this.castReportAdditionalReports(report.additional_reports),
      additionalInfo: report.additional_info,
      deletedAdditionalReports: [],
      deletedOrders: [],
      deletedAlterations: [],
      encashment: report.encashment,
      manager: null,
      reportDate: new Date(report.report_date),
      cashRegisterBalance: report.cash_register_balance,
    };
  }

  castReportAdditionalReports(additionalReports: ReportAdditionalReport[])
      : AdditionalReportProps[] {
    return additionalReports.map((additionalReport) => {
      let data;
      if (additionalReport.type === 'putting_money') {
        const modelData = (additionalReport.data as ReportAdditionalReportPuttingMoney);
        data = {
          performer: modelData.performer,
          sumCash: modelData.sum_cash,
          performerInvalid: false,
          comment: null,
          sumCashInvalid: false,
        } as AdditionalReportPuttingMoneyProps;
      } else if (additionalReport.type === 'purchase') {
        const modelData = (additionalReport.data as ReportAdditionalReportPurchase);
        data = {
          items: modelData.items.map((purchaseItem) => ({
            id: purchaseItem.id,
            name: purchaseItem.name,
            price: purchaseItem.price,
            quantity: purchaseItem.quantity,
            priceType: purchaseItem.price_type,
            nameInvalid: false,
            priceInvalid: false,
            quantityInvalid: false,
          })),
          deletedItems: [],
          itemsInvalid: false,
        } as AdditionalReportPurchaseProps;
      } else {
        const modelData = (additionalReport.data as ReportAdditionalReportOther);
        data = {
          description: modelData.description,
          sumCash: modelData.sum_cash,
          sumNonCash: modelData.sum_non_cash,
          descriptionInvalid: false,
          sumInvalid: false,
        } as AdditionalReportOtherProps;
      }
      return {
        id: additionalReport.id,
        type: additionalReport.type,
        data,
        typeInvalid: false,
      };
    });
  }

  castReportAlterations(alterations: ReportAlteration[]): AlterationProps[] {
    return alterations.map((alteration) => ({
      id: alteration.id,
      serviceType: alteration.service_type,
      client: alteration.client,
      clientType: alteration.client_type,
      cleaners: alteration.cleaners.map((cleaner) => ({
        id: cleaner.id,
        cleaner: {
          id: cleaner.cleaner.id,
          firstName: cleaner.cleaner.first_name,
          lastName: cleaner.cleaner.last_name,
        },
        income: cleaner.income,
        puttingMoney: cleaner.putting_money,
        cleanerInvalid: false,
      })),
      deletedCleaners: [],
      serviceTypeInvalid: false,
      cleanersInvalid: false,
      clientInvalid: false,
      clientTypeInvalid: false,
      taxiThere: {
        sumCash: alteration.taxi_there_sum_cash,
        sumNonCash: alteration.taxi_there_sum_non_cash,
      },
      taxiBack: {
        sumCash: alteration.taxi_back_sum_cash,
        sumNonCash: alteration.taxi_back_sum_non_cash,
      },
    }));
  }

  castReportOrders(orders: ReportOrder[]): OrderProps[] {
    return orders.map((order) => ({
      id: order.id,
      serviceType: order.service_type,
      client: order.client,
      clientType: order.client_type,
      sumCash: order.sum_cash,
      isCashFromEnvelope: order.is_cash_from_envelope,
      sumNonCash: order.sum_non_cash,
      isNonCashFromEnvelope: order.is_non_cash_from_envelope,
      envelopeRefillCash: order.envelope_refill_cash,
      envelopeRefillNonCash: order.envelope_refill_non_cash,
      cleanerDepartureSumCash: order.cleaner_departure_sum_cash,
      cleanerDepartureSumNonCash: order.cleaner_departure_sum_non_cash,
      nonCashType: order.non_cash_type,
      cleanerDepartureNonCashType: order.cleaner_departure_non_cash_type,
      cleaners: order.cleaners.map((cleaner) => ({
        id: cleaner.id,
        cleaner: {
          id: cleaner.cleaner.id,
          firstName: cleaner.cleaner.first_name,
          lastName: cleaner.cleaner.last_name,
        },
        income: cleaner.income,
        cleanerInvalid: false,
      })),
      deletedCleaners: [],
      sumInvalid: false,
      serviceTypeInvalid: false,
      cleanersInvalid: false,
      nonCashTypeInvalid: false,
      clientInvalid: false,
      clientTypeInvalid: false,
      cleanerDepartureNonCashTypeInvalid: false,
      cleanerDepartureSumInvalid: false,
      taxiThere: {
        sumCash: order.taxi_there_sum_cash,
        sumNonCash: order.taxi_there_sum_non_cash,
      },
      taxiBack: {
        sumCash: order.taxi_back_sum_cash,
        sumNonCash: order.taxi_back_sum_non_cash,
      },
    }));
  }

  // --------------
  // Orders
  // --------------
  showOrderModal(order: OrderProps|null = null) {
    if (order === null) {
      this.editOrder = {
        id: 0,
        serviceType: null,
        client: null,
        clientType: null,
        sumCash: 0,
        isCashFromEnvelope: false,
        sumNonCash: 0,
        isNonCashFromEnvelope: false,
        envelopeRefillCash: 0,
        envelopeRefillNonCash: 0,
        nonCashType: null,
        cleanerDepartureSumCash: 0,
        cleanerDepartureSumNonCash: 0,
        cleanerDepartureNonCashType: null,
        cleaners: [],
        deletedCleaners: [],
        serviceTypeInvalid: false,
        sumInvalid: false,
        cleanersInvalid: false,
        nonCashTypeInvalid: false,
        clientInvalid: false,
        clientTypeInvalid: false,
        cleanerDepartureSumInvalid: false,
        cleanerDepartureNonCashTypeInvalid: false,
        taxiThere: {
          sumCash: 0,
          sumNonCash: 0,
        },
        taxiBack: {
          sumCash: 0,
          sumNonCash: 0,
        },
      };
    } else {
      this.editOrder = JSON.parse(JSON.stringify(order));
    }
  }

  onSaveOrderButtonClick() {
    if (this.editOrder == null) return;
    if (!this.validateOrder()) return;
    if (this.editOrder.id === 0) {
      this.lastAddedOrderId--;
      this.editOrder.id = this.lastAddedOrderId;
      this.report.orders.push(this.editOrder);
    } else {
      const orderIndex = this.report.orders
        .findIndex((order) => order.id === this.editOrder?.id);
      this.report.orders[orderIndex] = this.editOrder;
    }
    this.onOrderModalClose();
  }

  get editOrderInvalid(): boolean {
    return this.editOrder == null || this.editOrder.sumInvalid || this.editOrder.serviceTypeInvalid
        || this.editOrder.cleanersInvalid || this.editOrder.nonCashTypeInvalid
        || this.editOrder.clientInvalid || this.editOrder.clientTypeInvalid
        || this.editOrder.cleanerDepartureNonCashTypeInvalid;
  }

  validateOrder(): boolean {
    if (this.editOrder == null) return false;
    let valid = true;
    if (this.editOrder.serviceType === null) {
      this.editOrder.serviceTypeInvalid = true;
      valid = false;
    }
    if (this.editOrder.client === null || this.editOrder.client.trim().length === 0) {
      this.editOrder.clientInvalid = true;
      valid = false;
    }
    if (this.editOrder.clientType === null) {
      this.editOrder.clientTypeInvalid = true;
      valid = false;
    }
    if (this.editOrder.sumCash === 0 && this.editOrder.sumNonCash === 0) {
      this.editOrder.sumInvalid = true;
      valid = false;
    }
    if (this.editOrder.cleaners.length === 0) {
      this.editOrder.cleanersInvalid = true;
      valid = false;
    }
    if (this.editOrder.sumNonCash !== 0 && this.editOrder.nonCashType === null) {
      this.editOrder.nonCashTypeInvalid = true;
      valid = false;
    }
    if (this.editOrder.cleanerDepartureSumNonCash !== 0
        && this.editOrder.cleanerDepartureNonCashType === null) {
      this.editOrder.cleanerDepartureNonCashTypeInvalid = true;
      valid = false;
    }
    return valid;
  }

  onOrderModalClose() {
    this.editOrder = null;
    this.orderModalTabPanelActiveKey = 1;
    this.editOrderCleaner = null;
  }

  orderModalTabPanelChange(key: number) {
    this.orderModalTabPanelActiveKey = key;
    this.editOrderCleaner = null;
  }

  removeOrder(deletedOrder: OrderProps) {
    this.report.orders = this.report.orders
      .filter((order) => deletedOrder.id !== order.id);
    if (deletedOrder.id > 0) {
      this.report.deletedOrders.push(deletedOrder.id);
    }
  }

  // --------------
  // Alterations
  // --------------
  alterationTaxiSum({ withCash = false, withNonCash = false }): number {
    let sum = 0;
    this.report.alterations.forEach((alteration) => {
      if (withCash) {
        sum += alteration.taxiThere.sumCash;
        sum += alteration.taxiBack.sumCash;
      }
      if (withNonCash) {
        sum += alteration.taxiThere.sumNonCash;
        sum += alteration.taxiBack.sumNonCash;
      }
    });
    return sum;
  }

  alterationPuttingMoneySum(): number {
    let sum = 0;
    this.report.alterations.forEach((alteration) => {
      alteration.cleaners.forEach((alterationCleaner) => {
        sum += alterationCleaner.puttingMoney;
      });
    });
    return sum;
  }

  alterationCleanersIncomeSum(): number {
    let sum = 0;
    this.report.alterations.forEach((alteration) => {
      alteration.cleaners.forEach((alterationCleaner) => {
        sum += alterationCleaner.income;
      });
    });
    return sum;
  }

  get alterationModalAddCleanerMode(): boolean {
    return this.editAlterationCleaner != null;
  }

  showAlterationModal(alteration: AlterationProps|null = null) {
    if (alteration === null) {
      this.editAlteration = {
        id: 0,
        serviceType: null,
        client: null,
        clientType: null,
        cleaners: [],
        deletedCleaners: [],
        serviceTypeInvalid: false,
        cleanersInvalid: false,
        clientInvalid: false,
        clientTypeInvalid: false,
        taxiThere: {
          sumCash: 0,
          sumNonCash: 0,
        },
        taxiBack: {
          sumCash: 0,
          sumNonCash: 0,
        },
      };
    } else {
      this.editAlteration = JSON.parse(JSON.stringify(alteration));
    }
  }

  onAlterationModalClose() {
    this.editAlteration = null;
    this.alterationModalTabPanelActiveKey = 1;
    this.editAlterationCleaner = null;
  }

  alterationModalTabPanelChange(key: number) {
    this.alterationModalTabPanelActiveKey = key;
    this.editAlterationCleaner = null;
  }

  showAlterationCleanerForm(cleaner: AlterationCleanerProps|null = null) {
    if (cleaner === null) {
      this.editAlterationCleaner = {
        id: 0,
        cleaner: null,
        income: 0,
        puttingMoney: 0,
        cleanerInvalid: false,
      };
    } else {
      this.editAlterationCleaner = JSON.parse(JSON.stringify(cleaner));
    }
  }

  removeAlterationCleaner(cleaner: AlterationCleanerProps) {
    if (this.editAlteration == null) return;
    this.editAlteration.cleaners = this.editAlteration.cleaners
      .filter((alterationCleaner) => alterationCleaner.id !== cleaner.id);
    if (cleaner.id > 0) {
      this.editAlteration.deletedCleaners.push(cleaner.id);
    }
  }

  onAlterationAddCleanerClose() {
    this.editAlterationCleaner = null;
  }

  onAlterationCleanerFormCleanChanged(event: Event) {
    if (this.editAlterationCleaner === null) return;
    this.editAlterationCleaner.cleanerInvalid = false;
    const selectedId = parseInt((event.target as HTMLInputElement).value, 0);
    this.editAlterationCleaner.cleaner = this.cleaners
      .find((cleaner) => cleaner.id === selectedId) ?? null;
  }

  validateAlterationCleaner(): boolean {
    if (this.editAlterationCleaner == null) return false;
    let valid = true;
    if (this.editAlterationCleaner.cleaner === null) {
      this.editAlterationCleaner.cleanerInvalid = true;
      valid = false;
    }
    return valid;
  }

  onAlterationCleanerSaveButtonClick() {
    if (this.editAlteration == null || this.editAlterationCleaner == null) return;
    if (!this.validateAlterationCleaner()) return;
    if (this.editAlterationCleaner.id === 0) {
      this.lastAddedAlterationCleanerId--;
      this.editAlterationCleaner.id = this.lastAddedAlterationCleanerId;
      this.editAlteration.cleaners.push(this.editAlterationCleaner);
    } else {
      const cleanerIndex = this.editAlteration.cleaners
        .findIndex((cleaner) => cleaner.id === this.editAlterationCleaner?.id);
      this.editAlteration.cleaners[cleanerIndex] = this.editAlterationCleaner;
    }
    this.editAlteration.cleanersInvalid = false;
    this.editAlterationCleaner = null;
  }

  onSaveAlterationButtonClick() {
    if (this.editAlteration == null) return;
    if (!this.validateAlteration()) return;
    if (this.editAlteration.id === 0) {
      this.lastAddedAlterationId--;
      this.editAlteration.id = this.lastAddedAlterationId;
      this.report.alterations.push(this.editAlteration);
    } else {
      const alterationIndex = this.report.alterations
        .findIndex((alteration) => alteration.id === this.editAlteration?.id);
      this.report.alterations[alterationIndex] = this.editAlteration;
    }
    this.onAlterationModalClose();
  }

  get editAlterationInvalid(): boolean {
    return this.editAlteration == null || this.editAlteration.serviceTypeInvalid
        || this.editAlteration.cleanersInvalid || this.editAlteration.clientInvalid
        || this.editAlteration.clientTypeInvalid;
  }

  validateAlteration(): boolean {
    if (this.editAlteration == null) return false;
    let valid = true;
    if (this.editAlteration.serviceType === null) {
      this.editAlteration.serviceTypeInvalid = true;
      valid = false;
    }
    if (this.editAlteration.client === null || this.editAlteration.client.trim().length === 0) {
      this.editAlteration.clientInvalid = true;
      valid = false;
    }
    if (this.editAlteration.clientType === null) {
      this.editAlteration.clientTypeInvalid = true;
      valid = false;
    }
    if (this.editAlteration.cleaners.length === 0) {
      this.editAlteration.cleanersInvalid = true;
      valid = false;
    }
    return valid;
  }

  removeAlteration(deletedAlteration: AlterationProps) {
    this.report.alterations = this.report.alterations
      .filter((alteration) => deletedAlteration.id !== alteration.id);
    if (deletedAlteration.id > 0) {
      this.report.deletedAlterations.push(deletedAlteration.id);
    }
  }

  // --------------
  // Additional Reports
  // --------------
  additionalReportDataInfo(additionalReport: AdditionalReportProps): string {
    const defaultResult = '-';
    const maxStringLen = 35;
    if (additionalReport.type === 'putting_money') {
      return (additionalReport.data as AdditionalReportPuttingMoneyProps)
        .performer ?? defaultResult;
    }
    if (additionalReport.type === 'purchase') {
      const data = (additionalReport.data as AdditionalReportPurchaseProps);
      let itemsEnumeration = '';
      for (const item of data.items) {
        if (itemsEnumeration.length > maxStringLen) {
          itemsEnumeration += '...';
          break;
        }
        if (itemsEnumeration.length > 0) {
          itemsEnumeration += ', ';
        }
        itemsEnumeration += item.name;
      }
      return itemsEnumeration;
    }
    if (additionalReport.type === 'other') {
      const description = (additionalReport.data as AdditionalReportOtherProps).description;
      if (description === null) return defaultResult;
      const shortDescription = description?.slice(0, maxStringLen);
      return shortDescription + (description?.length > shortDescription.length ? '...' : '');
    }
    return defaultResult;
  }

  additionalReportSum(additionalReport: AdditionalReportProps): number {
    const defaultResult = 0;
    if (additionalReport.type === 'putting_money') {
      return (additionalReport.data as AdditionalReportPuttingMoneyProps)
        .sumCash ?? defaultResult;
    }
    if (additionalReport.type === 'purchase') {
      const data = (additionalReport.data as AdditionalReportPurchaseProps);
      let sum = 0;
      for (const item of data.items) {
        sum += item.price * item.quantity;
      }
      return sum;
    }
    if (additionalReport.type === 'other') {
      const data = (additionalReport.data as AdditionalReportOtherProps);
      return data.sumCash + data.sumNonCash;
    }
    return defaultResult;
  }

  get editAdditionalReportInvalid(): boolean {
    if (this.editAdditionalReport === null) return true;
    if (this.editAdditionalReport.type === 'purchase') {
      const data = this.editAdditionalReport.data as AdditionalReportPurchaseProps;
      return data.itemsInvalid;
    }
    return false;
  }

  showAdditionalReportModal(additionalReport: AdditionalReportProps|null = null) {
    if (additionalReport === null) {
      this.editAdditionalReport = {
        id: 0,
        type: null,
        data: null,
        typeInvalid: false,
      };
    } else {
      this.editAdditionalReport = null;
      this.editAdditionalReport = JSON.parse(JSON.stringify(additionalReport));
    }
  }

  onAdditionalReportModalClose() {
    this.editAdditionalReport = null;
  }

  onSaveAdditionalReportButtonClick() {
    if (this.editAdditionalReport == null) return;
    if (!this.validateAdditionalReport()) return;
    if (this.editAdditionalReport.id === 0) {
      this.lastAddedAdditionalReportId--;
      this.editAdditionalReport.id = this.lastAddedAdditionalReportId;
      this.report.additionalReports.push(this.editAdditionalReport);
    } else {
      const reportIndex = this.report.additionalReports
        .findIndex((report) => report.id === this.editAdditionalReport?.id);
      this.report.additionalReports[reportIndex] = this.editAdditionalReport;
    }
    this.editAdditionalReport = null;
  }

  validateAdditionalReport(): boolean {
    if (this.editAdditionalReport == null) return false;
    let valid = true;
    if (this.editAdditionalReport.type === null) {
      this.editAdditionalReport.typeInvalid = true;
      return false;
    }
    switch (this.editAdditionalReport.type) {
      case 'putting_money': {
        const data = this.editAdditionalReport.data as AdditionalReportPuttingMoneyProps;
        if (data.performer === null || data.performer.trim().length === 0) {
          data.performerInvalid = true;
          valid = false;
        }
        if (data.sumCash === 0) {
          data.sumCashInvalid = true;
          valid = false;
        }
      } break;
      case 'purchase': {
        const data = this.editAdditionalReport.data as AdditionalReportPurchaseProps;
        if (data.items.length === 0) {
          data.itemsInvalid = true;
          valid = false;
        }
        data.items.forEach((item) => {
          if (item.name === null || item.name.trim().length === 0) {
            item.nameInvalid = true;
            valid = false;
          }
          if (item.price === 0) {
            item.priceInvalid = true;
            valid = false;
          }
          if (item.quantity < 1) {
            item.quantityInvalid = true;
            valid = false;
          }
        });
      } break;
      case 'other': {
        const data = this.editAdditionalReport.data as AdditionalReportOtherProps;
        if (data.description === null || data.description.length === 0) {
          data.descriptionInvalid = true;
          valid = false;
        }
        if (data.sumCash === 0 && data.sumNonCash === 0) {
          data.sumInvalid = true;
          valid = false;
        }
      } break;
      default:
        //
    }
    return valid;
  }

  onAdditionalReportTypeChanged(newValue: AdditionalReportType) {
    if (this.editAdditionalReport === null) return;
    this.editAdditionalReport.type = newValue;
    this.editAdditionalReport.typeInvalid = false;
    switch (newValue) {
      case 'putting_money':
        this.editAdditionalReport.data = {
          performer: null,
          sumCash: 0,
          comment: null,
          performerInvalid: false,
          sumCashInvalid: false,
        } as AdditionalReportPuttingMoneyProps;
        break;
      case 'purchase':
        this.editAdditionalReport.data = {
          items: [
            {
              id: -1,
              name: '',
              price: 0,
              priceType: 'cash',
              quantity: 1,
              nameInvalid: false,
              priceInvalid: false,
              quantityInvalid: false,
            },
          ],
          deletedItems: [],
          itemsInvalid: false,
        } as AdditionalReportPurchaseProps;
        break;
      case 'other':
        this.editAdditionalReport.data = {
          description: null,
          sumCash: 0,
          sumNonCash: 0,
          descriptionInvalid: false,
          sumInvalid: false,
        } as AdditionalReportOtherProps;
        break;
      default:
        //
    }
  }

  addAdditionalReportPurchaseItem() {
    if (this.editAdditionalReport === null || this.editAdditionalReport.type !== 'purchase') return;
    const id = ((this.editAdditionalReport.data as AdditionalReportPurchaseProps)
      .items.length + 1) * -1;
    (this.editAdditionalReport.data as AdditionalReportPurchaseProps).items.push({
      id,
      name: '',
      price: 0,
      priceType: 'cash',
      quantity: 1,
      nameInvalid: false,
      priceInvalid: false,
      quantityInvalid: false,
    });
    (this.editAdditionalReport.data as AdditionalReportPurchaseProps).itemsInvalid = false;
  }

  removeAdditionalReportPurchaseItem(removeIndex: number) {
    if (this.editAdditionalReport === null || this.editAdditionalReport.type !== 'purchase') return;
    const items = (this.editAdditionalReport.data as AdditionalReportPurchaseProps).items;
    if (items[removeIndex].id > 0) {
      (this.editAdditionalReport.data as AdditionalReportPurchaseProps)
        .deletedItems.push(items[removeIndex].id);
    }
    (this.editAdditionalReport.data as AdditionalReportPurchaseProps).items = items
      .filter((item, index) => index !== removeIndex);
  }

  removeAdditionalReport(deletedAdditionalReport: AdditionalReportProps) {
    this.report.additionalReports = this.report.additionalReports
      .filter((report) => deletedAdditionalReport.id !== report.id);
    if (deletedAdditionalReport.id > 0) {
      this.report.deletedAdditionalReports.push(deletedAdditionalReport.id);
    }
  }

  // --------------
  // Cleaners
  // --------------
  get orderModalAddCleanerMode(): boolean {
    return this.editOrderCleaner != null;
  }

  validateOrderCleaner(): boolean {
    if (this.editOrderCleaner == null) return false;
    let valid = true;
    if (this.editOrderCleaner.cleaner === null) {
      this.editOrderCleaner.cleanerInvalid = true;
      valid = false;
    }
    return valid;
  }

  showOrderCleanerForm(cleaner: OrderCleanerProps|null = null) {
    if (cleaner === null) {
      this.editOrderCleaner = {
        id: 0,
        cleaner: null,
        income: 0,
        cleanerInvalid: false,
      };
    } else {
      this.editOrderCleaner = JSON.parse(JSON.stringify(cleaner));
    }
  }

  onOrderCleanerSaveButtonClick() {
    if (this.editOrder == null || this.editOrderCleaner == null) return;
    if (!this.validateOrderCleaner()) return;
    if (this.editOrderCleaner.id === 0) {
      this.lastAddedOrderCleanerId--;
      this.editOrderCleaner.id = this.lastAddedOrderCleanerId;
      this.editOrder.cleaners.push(this.editOrderCleaner);
    } else {
      const cleanerIndex = this.editOrder.cleaners
        .findIndex((cleaner) => cleaner.id === this.editOrderCleaner?.id);
      this.editOrder.cleaners[cleanerIndex] = this.editOrderCleaner;
    }
    this.editOrder.cleanersInvalid = false;
    this.editOrderCleaner = null;
  }

  onOrderAddCleanerClose() {
    this.editOrderCleaner = null;
  }

  onOrderCleanerFormCleanChanged(event: Event) {
    if (this.editOrderCleaner === null) return;
    this.editOrderCleaner.cleanerInvalid = false;
    const selectedId = parseInt((event.target as HTMLInputElement).value, 0);
    this.editOrderCleaner.cleaner = this.cleaners
      .find((cleaner) => cleaner.id === selectedId) ?? null;
  }

  calculateCleanerIncome(incomePercent: number) {
    if (this.editOrder == null || this.editOrderCleaner == null) return;
    this.editOrderCleaner.income = (this.editOrder.sumCash
        + this.editOrder.sumNonCash) * (incomePercent / 100);
  }

  removeOrderCleaner(cleaner: OrderCleanerProps) {
    if (this.editOrder == null) return;
    this.editOrder.cleaners = this.editOrder.cleaners
      .filter((orderCleaner) => orderCleaner.id !== cleaner.id);
    if (cleaner.id > 0) {
      this.editOrder.deletedCleaners.push(cleaner.id);
    }
  }

  // --------------
  // Order Total Sum
  // --------------
  orderCleanersIncomeTotal(order: OrderProps): number {
    let total = 0;
    order.cleaners.forEach((cleaner) => {
      total += cleaner.income;
    });

    return total;
  }

  ordersTotalSum({
    withCash = false,
    withNonCash = false,
    withCleaning = false,
    withDryCleaning = false,
  }): number {
    let sum = 0;
    for (const order of this.report.orders) {
      // eslint-disable-next-line no-continue
      if (!withCleaning && order.serviceType === 'cleaning') continue;
      // eslint-disable-next-line no-continue
      if (!withDryCleaning && order.serviceType === 'dry_cleaning') continue;
      if (withCash) {
        sum += order.sumCash
          - this.orderCleanersIncomeTotal(order)
          - order.envelopeRefillCash;
      }
      if (withNonCash) {
        sum += order.sumNonCash;
      }
    }
    return sum;
  }

  get ordersCleaningTotalCashSum(): number {
    return this.ordersTotalSum({
      withCash: true,
      withCleaning: true,
    });
  }

  get ordersCleaningTotalNonCashSum(): number {
    return this.ordersTotalSum({
      withNonCash: true,
      withCleaning: true,
    });
  }

  get ordersCleaningTotalSum(): number {
    return this.ordersTotalSum({
      withCash: true,
      withNonCash: true,
      withCleaning: true,
    });
  }

  get ordersDryCleaningTotalCashSum(): number {
    return this.ordersTotalSum({
      withCash: true,
      withDryCleaning: true,
    });
  }

  get ordersDryCleaningTotalNonCashSum(): number {
    return this.ordersTotalSum({
      withNonCash: true,
      withDryCleaning: true,
    });
  }

  get ordersDryCleaningTotalSum(): number {
    return this.ordersTotalSum({
      withCash: true,
      withNonCash: true,
      withDryCleaning: true,
    });
  }

  ordersCleanerDepartureTotalSum({
    withCash = false,
    withNonCash = false,
    withCleaning = false,
    withDryCleaning = false,
  }): number {
    let sum = 0;
    for (const order of this.report.orders) {
      // eslint-disable-next-line no-continue
      if (!withCleaning && order.serviceType === 'cleaning') continue;
      // eslint-disable-next-line no-continue
      if (!withDryCleaning && order.serviceType === 'dry_cleaning') continue;
      if (withCash) {
        sum += order.cleanerDepartureSumCash;
      }
      if (withNonCash) {
        sum += order.cleanerDepartureSumNonCash;
      }
    }
    return sum;
  }

  get ordersCleaningCleanerDepartureTotalCashSum(): number {
    return this.ordersCleanerDepartureTotalSum({
      withCash: true,
      withCleaning: true,
    });
  }

  get ordersCleaningCleanerDepartureTotalNonCashSum(): number {
    return this.ordersCleanerDepartureTotalSum({
      withNonCash: true,
      withCleaning: true,
    });
  }

  get ordersCleaningCleanerDepartureTotalSum(): number {
    return this.ordersCleanerDepartureTotalSum({
      withCash: true,
      withNonCash: true,
      withCleaning: true,
    });
  }

  get ordersDryCleaningCleanerDepartureTotalCashSum(): number {
    return this.ordersCleanerDepartureTotalSum({
      withCash: true,
      withDryCleaning: true,
    });
  }

  get ordersDryCleaningCleanerDepartureTotalNonCashSum(): number {
    return this.ordersCleanerDepartureTotalSum({
      withNonCash: true,
      withDryCleaning: true,
    });
  }

  get ordersDryCleaningCleanerDepartureTotalSum(): number {
    return this.ordersCleanerDepartureTotalSum({
      withCash: true,
      withNonCash: true,
      withDryCleaning: true,
    });
  }

  // --------------
  // Order Taxi Total Sum
  // --------------
  ordersTaxiTotalSum({
    withCash = false,
    withNonCash = false,
    withCleaning = false,
    withDryCleaning = false,
  }): number {
    let sum = 0;
    for (const order of this.report.orders) {
      // eslint-disable-next-line no-continue
      if (!withCleaning && order.serviceType === 'cleaning') continue;
      // eslint-disable-next-line no-continue
      if (!withDryCleaning && order.serviceType === 'dry_cleaning') continue;
      if (withCash) {
        sum += order.taxiThere.sumCash + order.taxiBack.sumCash;
      }
      if (withNonCash) {
        sum += order.taxiThere.sumNonCash + order.taxiBack.sumNonCash;
      }
    }
    return sum;
  }

  get ordersCleaningTaxiTotalCashSum(): number {
    return this.ordersTaxiTotalSum({
      withCash: true,
      withCleaning: true,
    });
  }

  get ordersCleaningTaxiTotalNonCashSum(): number {
    return this.ordersTaxiTotalSum({
      withNonCash: true,
      withCleaning: true,
    });
  }

  get ordersCleaningTaxiTotalSum(): number {
    return this.ordersTaxiTotalSum({
      withCash: true,
      withNonCash: true,
      withCleaning: true,
    });
  }

  get ordersDryCleaningTaxiTotalCashSum(): number {
    return this.ordersTaxiTotalSum({
      withCash: true,
      withDryCleaning: true,
    });
  }

  get ordersDryCleaningTaxiTotalNonCashSum(): number {
    return this.ordersTaxiTotalSum({
      withNonCash: true,
      withDryCleaning: true,
    });
  }

  get ordersDryCleaningTaxiTotalSum(): number {
    return this.ordersTaxiTotalSum({
      withCash: true,
      withNonCash: true,
      withDryCleaning: true,
    });
  }

  // --------------
  // Cash Register
  // --------------
  get cashRegisterRefillsSum(): number {
    let sum = 0;
    this.report.additionalReports.forEach((additionalReport) => {
      if (additionalReport.type === 'putting_money') {
        sum += (additionalReport.data as AdditionalReportPuttingMoneyProps).sumCash;
      }
    });
    return sum;
  }

  calcPurchasesCashSum({ withCash = false, withNonCash = false }): number {
    let sum = 0;
    this.report.additionalReports.forEach((additionalReport) => {
      if (additionalReport.type === 'purchase') {
        (additionalReport.data as AdditionalReportPurchaseProps).items
          .forEach((purchaseItem) => {
            if (withCash && purchaseItem.priceType === 'cash') {
              sum += purchaseItem.price * purchaseItem.quantity;
            }
            if (withNonCash && purchaseItem.priceType === 'non_cash') {
              sum += purchaseItem.price * purchaseItem.quantity;
            }
          });
      }
    });
    return sum;
  }

  get purchasesCashSum(): number {
    return this.calcPurchasesCashSum({ withCash: true });
  }

  get purchasesNonCashSum(): number {
    return this.calcPurchasesCashSum({ withNonCash: true });
  }

  get purchasesTotalSum(): number {
    return this.calcPurchasesCashSum({
      withCash: true,
      withNonCash: true,
    });
  }

  otherSpendingsSum({ withCash = false, withNonCash = false }): number {
    let sum = 0;
    this.report.additionalReports.forEach((additionalReport) => {
      if (additionalReport.type === 'other') {
        if (withCash) {
          sum += (additionalReport.data as AdditionalReportOtherProps).sumCash;
        }
        if (withNonCash) {
          sum += (additionalReport.data as AdditionalReportOtherProps).sumNonCash;
        }
      }
    });
    return sum;
  }

  calcCashRegisterTotal(cashRegisterBalance: number): number {
    return cashRegisterBalance
      + this.cashRegisterRefillsSum
      + this.ordersCleaningTotalCashSum
      + this.ordersCleaningCleanerDepartureTotalCashSum
      + this.ordersDryCleaningTotalCashSum
      + this.ordersDryCleaningCleanerDepartureTotalCashSum
      - this.ordersCleaningTaxiTotalCashSum
      - this.ordersDryCleaningTaxiTotalCashSum
      - this.purchasesCashSum
      - this.otherSpendingsSum({ withCash: true })
      - this.alterationTaxiSum({ withCash: true })
      - this.alterationCleanersIncomeSum()
      + this.alterationPuttingMoneySum();
  }

  get cashRegisterTotal(): number {
    return this.calcCashRegisterTotal(this.cashRegisterBalance);
  }

  calcCashRegisterRemainingBalance(cashRegisterTotal: number): number {
    return cashRegisterTotal - this.report.encashment;
  }

  get cashRegisterRemainingBalance(): number {
    return this.calcCashRegisterRemainingBalance(this.cashRegisterTotal);
  }
}
