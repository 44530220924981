
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt.vue';

@Options({
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  computed: mapGetters({
    title: 'headerTitle',
  }),
})
export default class AppHeader extends Vue {
}
