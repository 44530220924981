import { AxiosPromise } from 'axios';
import moment from 'moment';
import api from '@/repositories/api';
import { DataResponse, ReportDateChecking, ReportModel } from '@/types';
import { ReportListItem } from '@/types/api/reports';

export default {
  getList(): AxiosPromise<DataResponse<ReportListItem[]>> {
    return api.get('/reports');
  },
  get(id: number): AxiosPromise<DataResponse<ReportModel>> {
    return api.get(`/reports/${id}`);
  },
  checkDate(date: Date): AxiosPromise<ReportDateChecking> {
    return api.post('/reports/check-date', {
      date: moment(date).format('YYYY-MM-DD'),
    });
  },
  create(data: any): AxiosPromise<DataResponse<ReportModel>> {
    return api.post('/reports', data);
  },
  update(data: any, id: number): AxiosPromise<DataResponse<ReportModel>> {
    return api.put(`/reports/${id}`, data);
  },
  delete(id: number): AxiosPromise<DataResponse<ReportModel>> {
    return api.delete(`/reports/${id}`);
  },
};
