import { AxiosPromise } from 'axios';
import {
  DataResponse,
  CashRegisterApiResponseData,
} from '@/types';
import api from '@/repositories/api';

export default {
  get(): AxiosPromise<DataResponse<CashRegisterApiResponseData>> {
    return api.get('/cash-register');
  },
};
