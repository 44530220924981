
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import { Color } from '@coreui/vue/dist/components/props';
import { AlertMessage, AlertType } from '@/store/modules/Alert';

@Options({
  name: 'AppAlert',
  computed: mapGetters({
    messages: 'alertMessages',
  }),
  watch: {
    messages() {
      if (this.messages) {
        // setTimeout(() => {
        // this.$store.dispatch('clearMessage');
        // }, 5000);
      }
    },
  },
})
export default class AppAlert extends Vue {
  readonly messages!: string | null;
  colors = {
    error: 'danger',
    success: 'success',
  };

  clearAlert(): void {
    // this.$store.dispatch('clearMessage');
  }

  onClose(message: AlertMessage): void {
    this.$store.dispatch('removeAlertMessage', message.id);
  }
}
