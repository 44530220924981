import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CToastBody = _resolveComponent("CToastBody")!
  const _component_CToastClose = _resolveComponent("CToastClose")!
  const _component_CToast = _resolveComponent("CToast")!
  const _component_CToaster = _resolveComponent("CToaster")!

  return (_openBlock(), _createBlock(_component_CToaster, { placement: "top-end" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
        return (_openBlock(), _createBlock(_component_CToast, {
          key: message,
          autohide: false,
          color: _ctx.colors[message.type],
          class: "text-white align-items-center"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_CToastBody, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(message.text), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_CToastClose, {
                class: "me-2 m-auto",
                white: "",
                onClose: ($event: any) => (_ctx.onClose(message))
              }, null, 8, ["onClose"])
            ])
          ]),
          _: 2
        }, 1032, ["color"]))
      }), 128))
    ]),
    _: 1
  }))
}