
import { Options, Vue } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { mapGetters } from 'vuex';
import AppInput from '@/components/AppInput.vue';
import {
  DataResponse,
  CleanerModel,
  CleanerProps,
} from '@/types';
import { convertUnprocessableContentResponseToHtml } from '@/utils/api/unprocessable-content-handler';
import cleaners from '@/repositories/api/cleaners';
import { cleanerApiCaster } from '@/utils/cleaner/cleaner-api-caster';

@Options({
  name: 'CleanersForm',
  computed: mapGetters({
    appSettings: 'appSettings',
  }),
  components: {
    AppInput,
  },
})
export default class CleanersForm extends Vue {
  cleaner: CleanerProps = this.emptyCleaner();
  isLoading = true;
  savingLoading = false;
  validationErrors: HTMLElement|null = null;

  created(): void {
    const cleanerId = +this.$route.params.cleanerId || null;
    if (cleanerId !== null) {
      this.$store.commit('SET_HEADER_TITLE', 'Редактирование клинера');
      this.loadCleaner(cleanerId);
    } else {
      this.isLoading = false;
      this.$store.commit('SET_HEADER_TITLE', 'Создание клинера');
    }
  }

  validateCleaner(): boolean {
    let valid = true;
    if (this.cleaner.firstName === null || this.cleaner.firstName.trim().length === 0) {
      this.cleaner.firstNameInvalid = true;
      valid = false;
    }
    if (this.cleaner.lastName === null || this.cleaner.lastName.trim().length === 0) {
      this.cleaner.lastNameInvalid = true;
      valid = false;
    }
    return valid;
  }

  async saveCleaner() {
    const data = cleanerApiCaster(this.cleaner);
    this.validationErrors = null;
    if (!this.validateCleaner()) return;
    this.savingLoading = true;
    try {
      const response = this.cleaner.id === null
        ? await cleaners.create(data)
        : await cleaners.update(data, this.cleaner.id);
      this.savingLoading = false;
      if (response.status !== 200 && response.status !== 201) {
        this.$store.dispatch('showAlertError', 'Произошла ошибка. Попробуйте снова');
      } else {
        this.$router.push('/cleaners?success-save');
      }
    } catch (e) {
      if (e.response !== undefined && e.response.status === 422 && e.response.data !== undefined) {
        this.validationErrors = convertUnprocessableContentResponseToHtml(e.response.data);
      } else {
        this.$store.dispatch('showAlertError', 'Произошла ошибка. Попробуйте снова');
      }
    } finally {
      this.savingLoading = false;
    }
  }

  emptyCleaner(): CleanerProps {
    return {
      id: null,
      firstName: null,
      lastName: null,
      isFired: false,
      firstNameInvalid: false,
      lastNameInvalid: false,
    };
  }

  loadCleaner(cleanerId: number) {
    cleaners
      .get(cleanerId)
      .then((value: AxiosResponse<DataResponse<CleanerModel>>) => {
        this.castCleaner(value.data.data);
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке отчета');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  castCleaner(cleaner: CleanerModel) {
    this.cleaner = {
      id: cleaner.id,
      firstName: cleaner.first_name,
      lastName: cleaner.last_name,
      isFired: cleaner.is_fired,
      firstNameInvalid: false,
      lastNameInvalid: false,
    };
  }
}
