import Inputmask from 'inputmask';
import { currencyMaskOptions } from '@/app.config';
import {
  AdditionalReportType, ClientType, NonCashType, OrderServiceType,
} from '@/types';

const formatCurrency = (value: number): string => Inputmask
  .format(value.toString(), currencyMaskOptions);

const orderServiceTypes = {
  cleaning: 'Уборка',
  dry_cleaning: 'Химчистка',
} as { [key in OrderServiceType]: string };

const nonCashTypes = {
  qr: 'QR',
  account: 'По счету',
  personal_bank_card: 'Перевод на личную карту',
} as { [key in NonCashType]: string };

const clientTypes = {
  new: 'Новый',
  regular: 'Постоянный',
} as { [key in ClientType]: string };

const additionalReportTypes = {
  putting_money: 'Внесение средств в кассу',
  purchase: 'Закуп',
  other: 'Другие затраты',
} as { [key in AdditionalReportType]: string };

export default {
  formatCurrency,
  orderServiceTypes,
  nonCashTypes,
  clientTypes,
  additionalReportTypes,
};
