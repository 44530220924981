
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Inputmask from 'inputmask';

export type AppInputType = 'text' | 'number';

@Options({
  name: 'AppInput',
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    type: {
      type: String as PropType<AppInputType>,
      required: false,
      default: 'text',
    },
    mask: {
      type: Object as PropType<Inputmask.Instance>,
      required: false,
      default: null,
    },
    autoUnmask: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  watch: {
    localValue() {
      this.$emit('update:modelValue', this.unmaskedValue);
    },
    modelValue() {
      this.localValue = this.modelValue;
    },
  },
})
export default class AppInput extends Vue {
  readonly mask!: Inputmask.Instance | null
  readonly modelValue!: string
  readonly type!: AppInputType
  readonly autoUnmask!: boolean
  maskInput: Inputmask.Instance | null = null;
  localValue: any = this.modelValue;

  $refs!: {
    input: HTMLFormElement
  }

  mounted(): void {
    if (this.mask != null) {
      this.maskInput = this.mask.mask(this.$refs.input.$el);
    }
  }

  get unmaskedValue(): string|number {
    let value = this.localValue;
    if (this.maskInput != null && this.autoUnmask) {
      this.maskInput.setValue(value);
      value = this.maskInput.unmaskedvalue();
    }
    if (this.type === 'number') {
      value = parseFloat(value);
      if (Number.isNaN(value)) {
        value = 0;
      }
    }
    return value;
  }
}
