
import { Options, Vue } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import cleaners from '@/repositories/api/cleaners';
import { DataResponse, CleanerListItem } from '@/types';

export interface CleanerListItemProps {
  id: number,
  firstName: string,
  lastName: string,
  isFired: boolean,
}

@Options({
  name: 'CleanersList',
})
export default class CleanersList extends Vue {
  isLoading = true;
  cleaners: CleanerListItemProps[] = [];
  successSave = false;
  showFired = false;

  checkSuccessMessage(): void {
    if (this.$route.query['success-save'] === undefined) return;
    this.successSave = true;
    this.$router.replace(
      this.$route.fullPath.replace(/success-save=?[^&]*&?/, ''),
    );
  }

  get cleanersForList(): CleanerListItemProps[] {
    return this.showFired
      ? this.cleaners
      : this.cleaners.filter((cleaner) => !cleaner.isFired);
  }

  created(): void {
    this.$store.commit('SET_HEADER_TITLE', 'Список клинеров');
    this.checkSuccessMessage();
    cleaners
      .getList()
      .then((value: AxiosResponse<DataResponse<CleanerListItem[]>>) => {
        this.cleaners = value.data.data.map((item) => ({
          id: item.id,
          firstName: item.first_name,
          lastName: item.last_name,
          isFired: item.is_fired,
        }));
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке клинеров');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
