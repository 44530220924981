
import { onMounted, ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import router from '@//router';

@Options({
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref();

    const getBreadcrumbs = () => router.currentRoute.value.matched.map((route) => ({
      active: route.path === router.currentRoute.value.fullPath,
      name: route.name,
      path: `${router.options.history.base}${route.path}`,
    }));

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs();
    });

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs();
    });

    return {
      breadcrumbs,
    };
  },
})
export default class AppBreadcrumb extends Vue {
}
