
import { Options, Vue } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { mapGetters } from 'vuex';
import AppInput from '@/components/AppInput.vue';
import {
  DataResponse,
  UserModel,
  UserProps,
  UserRole,
} from '@/types';
import { convertUnprocessableContentResponseToHtml } from '@/utils/api/unprocessable-content-handler';
import users from '@/repositories/api/users';
import { maskPhoneString, phoneMask } from '@/app.config';
import { userApiCaster } from '@/utils/user/user-api-caster';

export interface userRoleOption {
  value: UserRole,
  label: string,
  disabled: boolean,
}

@Options({
  name: 'UsersForm',
  components: {
    AppInput,
  },
})
export default class UsersForm extends Vue {
  phoneMask = phoneMask;
  user: UserProps = this.emptyUser();
  isLoading = true;
  savingLoading = false;
  validationErrors: HTMLElement|null = null;
  hiddenPassword = true;
  hiddenPasswordConfirmation = true;
  userRolesOptions: userRoleOption[] = [
    {
      value: 'user',
      label: 'Пользователь',
      disabled: true,
    },
    {
      value: 'admin',
      label: 'Админ',
      disabled: false,
    },
  ];

  get isEditing(): boolean {
    return this.user.id !== null;
  }

  created(): void {
    const userId = +this.$route.params.userId || null;
    if (userId !== null) {
      this.$store.commit('SET_HEADER_TITLE', 'Редактирование пользователя');
      this.loadUser(userId);
    } else {
      this.isLoading = false;
      this.$store.commit('SET_HEADER_TITLE', 'Создание пользователя');
    }
  }

  validateUser(): boolean {
    let valid = true;
    if (this.user.name === null || this.user.name.trim().length === 0) {
      this.user.nameInvalid = true;
      valid = false;
    }
    if (this.user.login === null || this.user.login.length === 0
        || this.user.login.indexOf('_') !== -1) {
      this.user.loginInvalid = true;
      valid = false;
    }
    if (!this.isEditing || (this.user.password !== null && this.user.password.length > 0)) {
      if (this.user.password === null || this.user.password.length < 8) {
        this.user.passwordInvalid = true;
        valid = false;
      }
      if (this.user.password !== this.user.passwordConfirmation) {
        this.user.passwordConfirmationInvalid = true;
        valid = false;
      }
    }
    return valid;
  }

  async saveUser() {
    const data = userApiCaster(this.user);
    this.validationErrors = null;
    if (!this.validateUser()) return;
    this.savingLoading = true;
    try {
      const response = this.user.id === null
        ? await users.create(data)
        : await users.update(data, this.user.id);
      this.savingLoading = false;
      if (response.status !== 200 && response.status !== 201) {
        this.$store.dispatch('showAlertError', 'Произошла ошибка. Попробуйте снова');
      } else {
        this.$router.push('/users?success-save');
      }
    } catch (e) {
      if (e.response !== undefined && e.response.status === 422 && e.response.data !== undefined) {
        this.validationErrors = convertUnprocessableContentResponseToHtml(e.response.data);
      } else {
        this.$store.dispatch('showAlertError', 'Произошла ошибка. Попробуйте снова');
      }
    } finally {
      this.savingLoading = false;
    }
  }

  emptyUser(): UserProps {
    return {
      id: null,
      name: null,
      login: null,
      password: null,
      passwordConfirmation: null,
      roles: ['user'],
      isBlocked: false,
      nameInvalid: false,
      loginInvalid: false,
      passwordInvalid: false,
      passwordConfirmationInvalid: false,
    };
  }

  loadUser(userId: number) {
    users
      .get(userId)
      .then((value: AxiosResponse<DataResponse<UserModel>>) => {
        this.castUser(value.data.data);
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке отчета');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  castUser(user: UserModel) {
    this.user = {
      id: user.id,
      name: user.name,
      login: maskPhoneString(user.login.substr(1)),
      password: null,
      passwordConfirmation: null,
      isBlocked: user.is_blocked,
      roles: user.roles,
      nameInvalid: false,
      loginInvalid: false,
      passwordInvalid: false,
      passwordConfirmationInvalid: false,
    };
  }

  onRoleChanged(target: HTMLInputElement) {
    const value = target.value as UserRole;
    if (target.checked) {
      if (this.user.roles.indexOf(value) === -1) {
        this.user.roles.push(value);
      }
    } else {
      this.user.roles = this.user.roles.filter((role) => role !== value);
    }
  }
}
