import {
  CleanerProps,
} from '@/types';

export const cleanerApiCaster = (cleanerForm: CleanerProps): any => {
  const castCleaner = (): any => {
    let cleaner: any = {};

    if (cleanerForm.id !== null && cleanerForm.id > 0) {
      cleaner.id = cleanerForm.id;
      cleaner.is_fired = cleanerForm.isFired;
    }
    cleaner = {
      ...cleaner,
      ...{
        first_name: cleanerForm.firstName?.trim(),
        last_name: cleanerForm.lastName?.trim(),
      },
    };

    return cleaner;
  };

  return castCleaner();
};
