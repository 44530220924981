import moment from 'moment';
import {
  AdditionalReportOtherProps,
  AdditionalReportProps,
  AdditionalReportPurchaseItemProps,
  AdditionalReportPurchaseProps,
  AdditionalReportPuttingMoneyProps,
  AlterationCleanerProps,
  AlterationProps,
  OrderCleanerProps,
  OrderProps,
  ReportProps,
} from '@/types';

export const reportApiCaster = (reportForm: ReportProps): any => {
  const castOrderCleaners = (cleaners: OrderCleanerProps[]): any => cleaners
    .map<any>((cleaner) => ({
      ...(cleaner.id > 0 ? { id: cleaner.id } : null),
      ...{
        cleaner_id: cleaner.cleaner?.id,
        income: cleaner.income,
      },
    }));

  const castOrders = (orders: OrderProps[]): any => orders
    .map<any>((order) => ({
      ...(order.id > 0 ? { id: order.id } : null),
      ...{
        service_type: order.serviceType,
        client: order.client?.trim(),
        client_type: order.clientType,
        sum_cash: order.sumCash,
        is_cash_from_envelope: order.isCashFromEnvelope,
        sum_non_cash: order.sumNonCash,
        is_non_cash_from_envelope: order.isNonCashFromEnvelope,
        cleaner_departure_sum_cash: order.cleanerDepartureSumCash,
        cleaner_departure_sum_non_cash: order.cleanerDepartureSumNonCash,
        envelope_refill_cash: order.envelopeRefillCash,
        envelope_refill_non_cash: order.envelopeRefillNonCash,
        taxi_there_sum_cash: order.taxiThere.sumCash,
        taxi_there_sum_non_cash: order.taxiThere.sumNonCash,
        taxi_back_sum_cash: order.taxiBack.sumCash,
        taxi_back_sum_non_cash: order.taxiBack.sumNonCash,
        deleted_cleaners_ids: order.deletedCleaners,
        cleaners: castOrderCleaners(order.cleaners),
      },
      ...(order.nonCashType !== null ? { non_cash_type: order.nonCashType } : null),
      ...(order.cleanerDepartureNonCashType !== null
        ? { cleaner_departure_non_cash_type: order.cleanerDepartureNonCashType }
        : null),
    }));

  const castAlterationCleaners = (cleaners: AlterationCleanerProps[]): any => cleaners
    .map<any>((cleaner) => ({
      ...(cleaner.id > 0 ? { id: cleaner.id } : null),
      ...{
        cleaner_id: cleaner.cleaner?.id,
        income: cleaner.income,
        putting_money: cleaner.puttingMoney,
      },
    }));

  const castAlterations = (alterations: AlterationProps[]): any => alterations
    .map<any>((alteration) => ({
      ...(alteration.id > 0 ? { id: alteration.id } : null),
      ...{
        service_type: alteration.serviceType,
        client: alteration.client?.trim(),
        client_type: alteration.clientType,
        taxi_there_sum_cash: alteration.taxiThere.sumCash,
        taxi_there_sum_non_cash: alteration.taxiThere.sumNonCash,
        taxi_back_sum_cash: alteration.taxiBack.sumCash,
        taxi_back_sum_non_cash: alteration.taxiBack.sumNonCash,
        deleted_cleaners_ids: alteration.deletedCleaners,
        cleaners: castAlterationCleaners(alteration.cleaners),
      },
    }));

  const castAdditionalReportPurchaseItems = (purchaseItems: AdditionalReportPurchaseItemProps[]):
    any => purchaseItems
    .map<any>((purchaseItem) => ({
      ...(purchaseItem.id > 0 ? { id: purchaseItem.id } : null),
      name: purchaseItem.name?.trim(),
      price: purchaseItem.price,
      price_type: purchaseItem.priceType,
      quantity: purchaseItem.quantity,
    }));

  const castAdditionalReports = (additionalReports: AdditionalReportProps[]):
    any => additionalReports
    .map<any>((additionalReport) => ({
      ...(additionalReport.id > 0 ? { id: additionalReport.id } : null),
      ...{
        type: additionalReport.type,
      },
      ...(additionalReport.type !== 'putting_money' ? null : {
        putting_money: {
          performer: (additionalReport.data as AdditionalReportPuttingMoneyProps).performer?.trim(),
          sum_cash: (additionalReport.data as AdditionalReportPuttingMoneyProps).sumCash,
          comment: (additionalReport.data as AdditionalReportPuttingMoneyProps).comment?.trim(),
        },
      }),
      ...(additionalReport.type !== 'purchase' ? null : {
        purchase: {
          deleted_items_ids: (additionalReport.data as AdditionalReportPurchaseProps).deletedItems,
          items: castAdditionalReportPurchaseItems(
            (additionalReport.data as AdditionalReportPurchaseProps).items,
          ),
        },
      }),
      ...(additionalReport.type !== 'other' ? null : {
        other: {
          sum_cash: (additionalReport.data as AdditionalReportOtherProps).sumCash,
          sum_non_cash: (additionalReport.data as AdditionalReportOtherProps).sumNonCash,
          description: (additionalReport.data as AdditionalReportOtherProps).description?.trim(),
        },
      }),
    }));

  const castReport = (): any => {
    let report: any = {};

    if (reportForm.id !== null && reportForm.id > 0) {
      report.id = reportForm.id;
    }
    report = {
      ...report,
      ...{
        report_date: moment(reportForm.reportDate).format('YYYY-MM-DD'),
        encashment: reportForm.encashment,
        additional_info: reportForm.additionalInfo?.trim(),
        orders: castOrders(reportForm.orders),
        alterations: castAlterations(reportForm.alterations),
        additional_reports: castAdditionalReports(reportForm.additionalReports),
        deleted_orders_ids: reportForm.deletedOrders,
        deleted_alterations_ids: reportForm.deletedAlterations,
        deleted_additional_reports_ids: reportForm.deletedAdditionalReports,
      },
    };

    return report;
  };

  return castReport();
};
