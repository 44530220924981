import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import ReportsList from '@/views/reports/ReportsList.vue';
import ReportsForm from '@/views/reports/ReportsForm.vue';
import CleanersList from '@/views/cleaners/CleanersList.vue';
import CleanersForm from '@/views/cleaners/CleanersForm.vue';
import UsersForm from '@/views/users/UsersForm.vue';
import UsersList from '@/views/users/UsersList.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Вход' },
  },
  {
    path: '/',
    component: CabinetLayout,
    redirect: '/reports',
    children: [
      {
        path: '/reports',
        component: ReportsList,
        meta: { title: 'Отчеты' },
      },
      {
        path: '/reports/add',
        component: ReportsForm,
        meta: { title: 'Создать отчет' },
      },
      {
        path: '/reports/edit/:reportId',
        component: ReportsForm,
        meta: { title: 'Редактировать отчет' },
      },
      {
        path: '/cleaners',
        component: CleanersList,
        meta: { title: 'Клиенра' },
      },
      {
        path: '/cleaners/add',
        component: CleanersForm,
        meta: { title: 'Создать клиенра' },
      },
      {
        path: '/cleaners/edit/:cleanerId',
        component: CleanersForm,
        meta: { title: 'Редактировать клиенра' },
      },
      {
        path: '/users',
        component: UsersList,
        meta: { title: 'Пользователи' },
      },
      {
        path: '/users/add',
        component: UsersForm,
        meta: { title: 'Создать пользователя' },
      },
      {
        path: '/users/edit/:userId',
        component: UsersForm,
        meta: { title: 'Редактировать пользователя' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title(to);
//   next();
// });

export default router;
