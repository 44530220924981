import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row justify-content-start align-items-center" }
const _hoisted_2 = { class: "mb-0 ms-2 fw-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_CHeaderToggler = _resolveComponent("CHeaderToggler")!
  const _component_AppHeaderDropdownAccnt = _resolveComponent("AppHeaderDropdownAccnt")!
  const _component_CHeaderNav = _resolveComponent("CHeaderNav")!
  const _component_CContainer = _resolveComponent("CContainer")!
  const _component_CHeader = _resolveComponent("CHeader")!

  return (_openBlock(), _createBlock(_component_CHeader, {
    position: "sticky",
    class: "mb-4"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CContainer, { fluid: "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_CHeaderToggler, {
              class: "ps-1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('toggleSidebar')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CIcon, {
                  icon: "cil-menu",
                  size: "lg"
                })
              ]),
              _: 1
            }),
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
          ]),
          _createVNode(_component_CHeaderNav, null, {
            default: _withCtx(() => [
              _createVNode(_component_AppHeaderDropdownAccnt)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}