
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/vue';
import { useRoute } from 'vue-router';
import nav from '@/_nav';
import AppSidebarNavItem from '@/components/AppSidebarNavItem.vue';
import { ProfileData } from '@/types';

@Options({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
    AppSidebarNavItem,
  },
  computed: mapGetters({
    profile: 'myProfile',
  }),
})
export default class AppSidebarNav extends Vue {
  nav = nav;
  route = useRoute();
  readonly profile!: ProfileData;

  normalizePath(path: string) {
    return decodeURI(path)
      .replace(/#.*$/, '')
      .replace(/(index)?\.(html)$/, '');
  }

  isActiveLink(link: any) {
    if (link === undefined) {
      return false;
    }

    if (this.route.hash === link) {
      return true;
    }

    const currentPath = this.normalizePath(this.route.path);
    const targetPath = this.normalizePath(link);

    return currentPath === targetPath;
  }

  isActiveItem(item: any) {
    if (this.isActiveLink(item.to)) {
      return true;
    }

    if (item.items) {
      return item.items.some((child: any) => this.isActiveItem(child));
    }

    return false;
  }

  hasPermission(item: any): boolean {
    if (!item.userRoles) return true;
    if (this.profile === null) return false;
    let hasPermission = false;
    item.userRoles.forEach((role: string) => {
      if (this.profile.roles.indexOf(role) !== -1) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }
}
