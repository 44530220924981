
import { Options, Vue } from 'vue-class-component';
import { AxiosResponse } from 'axios';
import users from '@/repositories/api/users';
import { maskPhoneString } from '@/app.config';
import { DataResponse, UserListItem } from '@/types';

export interface UserListItemProps {
  id: number,
  name: string,
  login: string,
  isBlocked: string,
}

@Options({
  name: 'UsersList',
})
export default class UsersList extends Vue {
  maskPhoneString = maskPhoneString;
  isLoading = true;
  users: UserListItemProps[] = [];
  successSave = false;
  showBlocked = false;

  checkSuccessMessage(): void {
    if (this.$route.query['success-save'] === undefined) return;
    this.successSave = true;
    this.$router.replace(
      this.$route.fullPath.replace(/success-save=?[^&]*&?/, ''),
    );
  }

  get usersForList(): UserListItemProps[] {
    return this.showBlocked
      ? this.users
      : this.users.filter((user) => !user.isBlocked);
  }

  created(): void {
    this.$store.commit('SET_HEADER_TITLE', 'Список пользователей');
    this.checkSuccessMessage();
    users
      .getList()
      .then((value: AxiosResponse<DataResponse<UserListItem[]>>) => {
        this.users = value.data.data.map((item) => ({
          id: item.id,
          name: item.name,
          login: item.login.substr(1),
          isBlocked: item.is_blocked,
        }));
      })
      .catch((error: any) => {
        this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке пользователей');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
