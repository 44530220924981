import { App } from 'vue';
import config from '@/app.config';

export default {
  install(app: App): void {
    app.config.globalProperties.$config = config;
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $config: typeof config;
  }
}
