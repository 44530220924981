<template>
  <template v-if="inited">
    <app-alert/>
    <router-view/>
  </template>
  <div
      v-else
      class="position-fixed start-0 top-0 bottom-0 end-0 w-100 h-100
        d-flex flex-row justify-content-center align-items-center">
    <CSpinner color="primary"/>
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
<script>
import { Options, Vue } from 'vue-class-component';
import Cookie from 'js-cookie';
import AppAlert from '@/components/AppAlert.vue';
import { tokenCookieKey } from '@/app.config';

@Options({
  components: { AppAlert },
})
export default class App extends Vue {
  inited = false;

  created() {
    this.checkTokenExist();
  }

  checkTokenExist() {
    const hasToken = !!Cookie.get(this.$config.tokenCookieKey);
    if (!hasToken) {
      Cookie.remove(tokenCookieKey);
      if (!window.location.href.includes('/auth/login')) {
        window.location.href = '/auth/login';
      } else {
        this.inited = true;
      }
    } else {
      this.inited = true;
    }
  }
}
</script>
