import { UnprocessableContentResponse } from '@/types';

export const convertUnprocessableContentResponseToHtml = (error: UnprocessableContentResponse):
  HTMLElement => {
  const list = document.createElement('ul');

  Object.values(error.errors).forEach((fieldErrors) => {
    fieldErrors.forEach((error) => {
      const li = document.createElement('li');
      li.innerText = error;
      list.append(li);
    });
  });

  return list;
};
