import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { ProfileData } from '@/types';
import me from '@/repositories/api/me';

@Module
export default class Profile extends VuexModule {
  profile: ProfileData | null = null

  get myProfile(): ProfileData | null {
    return this.profile;
  }

  get isCurrentUserAdmin(): boolean | null {
    return this.profile?.roles.indexOf('admin') !== -1;
  }

  @Mutation
  SET_PROFILE(profile: ProfileData | null): void {
    this.profile = profile;
  }

  @Action({ commit: 'SET_PROFILE' })
  logout(): null {
    return null;
  }

  @Action({ commit: 'SET_PROFILE' })
  async getProfile(): Promise<ProfileData | null> {
    try {
      const res = await me.get();
      if (res.data.data) {
        return res.data.data;
      }
      this.context.dispatch('showAlertError', 'Произошла ошибка при загрузке приложения');
      return null;
    } catch {
      this.context.dispatch('showAlertError', 'Произошла ошибка при загрузке приложения');
      return null;
    }
  }

  @Action
  async initProfile(): Promise<void> {
    await this.context.dispatch('getProfile');
  }
}
