import { AxiosPromise } from 'axios';
import api from '@/repositories/api';
import { UserModel, DataResponse } from '@/types';
import { UserListItem } from '@/types/api/users';

export default {
  getList(): AxiosPromise<DataResponse<UserListItem[]>> {
    return api.get('/users');
  },
  get(id: number): AxiosPromise<DataResponse<UserModel>> {
    return api.get(`/users/${id}`);
  },
  create(data: any): AxiosPromise<DataResponse<UserModel>> {
    return api.post('/users', data);
  },
  update(data: Date, id: number): AxiosPromise<DataResponse<UserModel>> {
    return api.put(`/users/${id}`, data);
  },
};
