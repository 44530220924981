import {
  UserProps,
} from '@/types';

export const userApiCaster = (userForm: UserProps): any => {
  const castUser = (): any => {
    let user: any = {};

    if (userForm.id !== null && userForm.id > 0) {
      user.id = userForm.id;
      user.is_blocked = userForm.isBlocked;
    }
    if (userForm.password !== null && userForm.password.length > 0) {
      user.password = userForm.password;
      user.password_confirmation = userForm.passwordConfirmation;
    }
    user = {
      ...user,
      ...{
        name: userForm.name?.trim(),
        login: userForm.login,
        roles: userForm.roles,
      },
    };

    return user;
  };

  return castUser();
};
